import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Description from "./Description";
import Allergens from "./Allergens";
import Price from "./Price";
import MenuTitle from "./MenuTitle";

export default function MenuDessert() {
	const { t } = useTranslation("menuDessert");

	return (
		<>
			<MenuTitle bold={t("dessert")} descr="doce fim" />
			<p>
				<Price>13.</Price>
				<Trans ns="menuDessert">pao café e doce de leite</Trans>.
				<Description>
					<Trans ns="menuDessert">brioche. coffee sauce. doce de leite. yoghurt ice cream</Trans>.
					<Allergens>1.7.</Allergens>
				</Description>
			</p>
			<p>
				<Price>11.</Price>
				<Trans ns="menuDessert">dame blanche</Trans>.
				<Description>
					<Trans ns="menuDessert">
						vanilla ice cream. hot chocolate sauce. whipped cream
					</Trans>
					.<Allergens>3.7.</Allergens>
				</Description>
			</p>
			<p>
				<Price>4.</Price>
				<Trans ns="menuDessert">cremerie francois ice cream</Trans>.
				<Description>
					<Trans ns="menuDessert">vanilla</Trans>.{" / "}
					<Trans ns="menuDessert">chocolate</Trans>.{" / "}
					<Trans ns="menuDessert">hazelnut</Trans>.{" / "}
					<Trans ns="menuDessert">strawberry</Trans>.{" "}
					<Allergens>3.6.7.</Allergens>
				</Description>
			</p>
			<p>
				<Price>13.</Price>
				<Trans ns="menuDessert">seasonal fruits</Trans>.
			</p>
			{/* <p>
				<Price>4.</Price>
				<Trans ns="menuDessert">sorbet</Trans>.{" "}
				<span className="small">
					<Trans ns="menuDessert">homemade</Trans>.
				</span>
				<Description>
					<Trans ns="menuDessert">lemon</Trans>.{" / "}
					<Trans ns="menuDessert">passion fruits</Trans>.<Allergens>1.5.8.</Allergens>
				</Description>
			</p> */}
		</>
	);
}
